@import url("https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700;900&display=swap");

html,
body {
  font-family: Merriweather, sans-serif;
  height: 100%;
  margin: 0;
  background-color: #f9efd8;
  /* overflow: hidden; */
}

.logoContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
}

a {
  color: black;
}

.logotext {
  margin-top: 3px;
  font-size: 14px;
  font-weight: 700;
  /* font-family: AvenirBlack; */
  margin-left: 4px;
}

.logoSvg {
  height: 18px;
}

.mainTitle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-weight: 700;
  margin-left: 20px;
  margin-right: 20px;
}

.chartSubtitle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 18px;
  opacity: 0.8;
  margin-top: 10px;
  margin-left: 50px;
  margin-right: 50px;
}

svg {
  width: 100%;
  overflow: visible;
}

#root {
  height: 100%;
}

.viz-wrapper {
  height: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.container .row.header {
  flex: 0 1 auto;
  font-size: 32px;
  margin-top: 20px;
}

.container .row.content {
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
}

.content-map {
  flex: 5;
}

.title-parameter {
  font-size: 1.25rem;
  margin-left: 10px;
  margin-right: 10px;
}

.legend-label {
  font-family: sans-serif;
}

.tooltip {
  position: absolute;
  display: block;
  pointer-events: none;
  padding: 10px;
  z-index: 10;
  width: 420px;
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 3px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.5);
  visibility: hidden;
}

.tooltip h4 {
  margin: 0;
}

.tooltip h6 {
  color: grey;
  padding-bottom: 20px;
  margin: 0;
}

.tooltip table {
  border-collapse: collapse;
  color: rgb(82, 82, 82);
  font-size: 12px;
  width: 100%;
}

.tooltip-table-header {
  border-bottom: 1px solid lightgrey;
  color: black;
}

#tooltip-table-body td:nth-child(n + 3) {
  text-align: right;
  padding-left: 10px;
}

#tooltip-table-body tr {
  border-bottom: 1px solid #eee;
}

.right {
  text-align: right;
}

.left {
  text-align: left;
}

.highlighted {
  stroke-width: 2px;
  stroke: #f9efd8;
}

.loading {
  font-size: 1.2rem;
  margin: 2rem;
}

.color-box span {
  padding: 2px;
  padding-left: 5px;
  margin: 0px;
  margin-right: 5px;
}

.color-box {
  padding: 6px;
  padding-right: 10px;
}

.tooltip-title-row {
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 30px;
}

#total-voters, #total-votes {
  font-size: 0.75rem;
  opacity: 0.5;
}

/* .chartSubtitle {
  display: flex;
  flex-direction: column;
} */